.gradient-animated {
    background: repeating-linear-gradient(to right, #fc4967 0, #ecb731 50%, #fc4967 100%);
    color: #fff;
    width: fit-content;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: 2s linear infinite forwards gradient;
    padding: 0 10px;
    border-radius: 9999px;
    margin-bottom: 24px;
    font-weight: 600;
}

@-webkit-keyframes gradient {
    100% {
        background-position-x: -200%;
        background-position-y: 0px;
    }

    0% {
        background-position-x: 0px;
        background-position-y: 0px;
    }
}

@-moz-keyframes gradient {
    100% {
        background-position-x: -200%;
        background-position-y: 0px;
    }

    0% {
        background-position-x: 0px;
        background-position-y: 0px;
    }
}

@keyframes gradient {
    100% {
        background-position-x: -200%;
        background-position-y: 0px;
    }

    0% {
        background-position-x: 0px;
        background-position-y: 0px;
    }
}